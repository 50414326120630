<template>
	<div>
		<div
			class="flex items-center justify-between border-b border-gris my-3 mt-6 pb-2 mx-2"
		>
			<div class="flex items-center justify-between">
				<svg
					class="fill-current text-white rounded-full bg-gray-400 w-8 h-8 mr-2"
					version="1.1"
					id="Capa_1"
					xmlns="http://www.w3.org/2000/svg"
					xmlns:xlink="http://www.w3.org/1999/xlink"
					x="0px"
					y="0px"
					viewBox="0 0 512 512"
					style="enable-background: new 0 0 512 512"
					xml:space="preserve"
				>
					<path
						d="M437.02,330.98c-27.883-27.882-61.071-48.523-97.281-61.018C378.521,243.251,404,198.548,404,148 C404,66.393,337.607,0,256,0S108,66.393,108,148c0,50.548,25.479,95.251,64.262,121.962 c-36.21,12.495-69.398,33.136-97.281,61.018C26.629,379.333,0,443.62,0,512h40c0-119.103,96.897-216,216-216s216,96.897,216,216 h40C512,443.62,485.371,379.333,437.02,330.98z M256,256c-59.551,0-108-48.448-108-108S196.449,40,256,40 c59.551,0,108,48.448,108,108S315.551,256,256,256z"
					/>
				</svg>

				<span class="">{{ this.$route.params.name }}</span>
			</div>
			<svg
				class="fill-current text-black h-8 w-16"
				version="1.1"
				id="Layer_1"
				xmlns="http://www.w3.org/2000/svg"
				xmlns:xlink="http://www.w3.org/1999/xlink"
				x="0px"
				y="0px"
				viewBox="0 220 512 40"
				style="enable-background: new 0 0 512 512"
				xml:space="preserve"
			>
				<g id="_13-car">
					<g id="linear_color">
						<circle cx="242.8" cy="291.1" r="8.2" />
						<circle cx="438.4" cy="291.1" r="8.2" />
						<path
							d="M485.5,226.6l-60-9.1l-57.5-47.3c-5.4-4.4-12.1-6.8-19-6.8h-79.6c-9.1,0-17.7,4.1-23.3,11.2l-34.5,43.1h-23.2 c-13.5,0-24.4,11-24.5,24.5v27.2c0,16.5,13.4,29.9,29.9,29.9h14.5c4.5,19,23.5,30.7,42.5,26.2c13-3.1,23.1-13.2,26.2-26.2H404 c4.5,19,23.5,30.7,42.5,26.2c13-3.1,23.1-13.2,26.2-26.2h9.1c13.5,0,24.4-11,24.5-24.5v-24C506.4,238.7,497.5,228.4,485.5,226.6z M357.7,182.8l42.5,34.9h-78.6v-38h27.5C352.2,179.7,355.3,180.8,357.7,182.8z M258.9,184.8c2.6-3.2,6.5-5.1,10.6-5.1h3.1v38 h-40.1L258.9,184.8z M242.8,310.1c-10.5,0-19-8.5-19-19s8.5-19,19-19c10.5,0,19,8.5,19,19C261.8,301.6,253.3,310.1,242.8,310.1z M438.4,310.1c-10.5,0-19-8.5-19-19s8.5-19,19-19s19,8.5,19,19C457.4,301.6,448.9,310.1,438.4,310.1z M490,274.8 c0,4.5-3.7,8.1-8.2,8.2h-9.1c-4.5-19-23.5-30.7-42.5-26.2c-13,3.1-23.1,13.2-26.2,26.2H277.1c-4.5-19-23.5-30.7-42.5-26.2 c-13,3.1-23.1,13.2-26.2,26.2h-14.5c-7.5,0-13.6-6.1-13.6-13.6v-27.2c0-4.5,3.7-8.1,8.2-8.2h92.4c4.5,0,8.2-3.6,8.2-8.2v-46.2 h16.3v46.2c0,4.5,3.6,8.2,8.2,8.2h112.5l57.1,8.7c4,0.6,6.9,4,6.9,8.1V274.8z"
						/>
						<path
							d="M324.3,239.5h-10.9c-4.5,0-8.2,3.6-8.2,8.2s3.6,8.2,8.2,8.2h10.9c4.5,0,8.2-3.6,8.2-8.2S328.8,239.5,324.3,239.5z"
						/>
						<circle cx="242.8" cy="291.1" r="8.2" />
						<circle cx="438.4" cy="291.1" r="8.2" />
					</g>
				</g>
				<rect x="63.2" y="193.1" width="120.9" height="16.3" />
				<rect x="4" y="234.1" width="120.9" height="16.3" />
				<rect x="29.7" y="275.4" width="120.9" height="16.3" />
			</svg>
		</div>
		<div class="flex items-start justify-between my-2 mx-2">
			<div class="w-4/6">
				<div>
					Orden de compra # {{ this.$route.params.id.padStart(4, "0") }}
				</div>
				<div
					v-if="
						estatusOrdenCompra === 'finalizado' ||
							estatusOrdenCompra === 'entregado'
					"
					class="border-b border-black mb-2 mt-1"
				>
					<div>
						<p class="font-bold">Cliente:</p>
						{{ firstLetterUpperCase(this.cliente.name) }}
					</div>
					<div>
						<p class="font-bold">Dirección:</p>
						{{ firstLetterUpperCase(this.cliente.address) }}
					</div>
				</div>
			</div>
			<div class="">
				<div>
					<div class="flex items-center justify-between">
						<svg
							width="20"
							height="20"
							viewBox="0 0 20 20"
							class="mr-1"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<circle cx="10" cy="10" r="10" fill="#3BB54A" />
						</svg>
						{{ firstLetterUpperCase(estatusOrdenCompra) }}
					</div>
				</div>
				<div class="flex items-center mt-2">
					<svg
						width="24"
						height="24"
						class="mr-1"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fill-rule="evenodd"
							clip-rule="evenodd"
							d="M11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20ZM11 7H12.5V12.25L17 14.92L16.25 16.15L11 13V7Z"
							fill="black"
							fill-opacity="0.54"
						/>
					</svg>
					{{ this.$route.params.hora }}
				</div>
			</div>
		</div>
		<div
			v-if="
				estatusOrdenCompra === 'ejecucion' || estatusOrdenCompra === 'espera'
			"
			class="font-bold ml-1 my-4 pt-2 border-t border-black w-4/5"
		>
			<div v-if="!mostrarListaCompletados" class="flex items-center">
				<svg
					width="32"
					height="32"
					viewBox="0 0 32 32"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M4 8V10.6667H28V8H4ZM13.3333 24H18.6667V21.3333H13.3333V24ZM24 17.3333H8V14.6667H24V17.3333Z"
						fill="#333333"
					/>
				</svg>
				Filtrar por:
			</div>
			<div v-if="!mostrarListaCompletados" class="ml-8 mt-6">
				<select
					class="
            rounded-lg
            p-1
            focus:outline-none
            bg-white
            border border-secundB
          "
					name="rubro"
					id=""
					v-model="filtroCompra"
					@click="reset()"
				>
					<option :value="true">Comprado</option>
					<option :value="false">No Comprado</option>
				</select>
			</div>
		</div>
		<div
			v-if="
				estatusOrdenCompra === 'espera' || estatusOrdenCompra === 'ejecucion'
			"
		>
			<div v-if="!mostrarListaCompletados">
				<div v-if="!filtroCompra">
					<div v-for="(pedido, k) in pedidosVendedor" :key="k">
						<tarjetaCompra
							:mostrarTarjeta="filtroCompra"
							:pedido="pedido"
							@eventoDuplicarTarjeta="duplicarTarjeta(k, pedido)"
						></tarjetaCompra>
					</div>
				</div>
				<div v-else>
					<div v-for="(pedido, k) in pedidosVendedor" :key="k">
						<tarjetaCompra
							:mostrarTarjeta="filtroCompra"
							:pedido="pedido"
							@eventoDuplicarTarjeta="duplicarTarjeta(k, pedido)"
						></tarjetaCompra>
					</div>
				</div>
			</div>
			<div v-else>
				<div
					v-for="(pedido, k) in pedidosVendedor"
					:key="k"
					class="flex mt-1 mx-auto font-bold justify-between w-full"
				>
					<div v-if="pedido.cantidadAdquirida != '0'" class="w-5/6 h-auto">
						<div
							class="flex my-4 w-full items-center justify-between font-bold px-2"
						>
							<div class="flex items-center">
								<p class="">
									{{ firstLetterUpperCase(pedido.nombre_producto) }}
									{{ formato(pedido.cantidadAdquirida) }}
									{{ firstLetterUpperCase(pedido.unidad) }}
								</p>
							</div>
							<div class="flex items-center">
								<div
									v-if="pedido.comprado == 0 || pedido.comprado === false"
									class="text-red-500"
								>
									No comprado
								</div>
								<div v-else class="text-green-500">Comprado</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div
			v-if="
				estatusOrdenCompra === 'espera' || estatusOrdenCompra === 'ejecucion'
			"
		>
			<botonP
				v-if="!mostrarListaCompletados"
				@click="comprar()"
				class="underline flex justify-center mb-10"
				botonpText="Comprar"
			/>
			<div class="flex justify-around my-8">
				<botonP
					v-if="mostrarListaCompletados"
					@click="recargarPedidoVendedor()"
					class="underline"
					botonpText="Volver a comprar"
				/>
				<router-link to="/VHome"
					><botonP
						v-if="mostrarListaCompletados"
						class="underline"
						botonpText="Regresar a home"
				/></router-link>
			</div>

			<div></div>
		</div>
		<div
			v-if="
				estatusOrdenCompra === 'completado' ||
					estatusOrdenCompra === 'finalizado'
			"
		>
			<div
				v-for="(pedido, k) in pedidosVendedor"
				:key="k"
				class="flex mt-1 mx-auto font-bold justify-between w-full"
			>
				<div v-if="pedido.cantidadAdquirida != '0'" class="w-5/6 h-auto">
					<div
						class="flex my-4 w-full items-center justify-between font-bold px-2"
					>
						<div class="flex items-center">
							<p class="">
								{{ firstLetterUpperCase(pedido.nombre_producto) }}
								{{ formato(pedido.cantidadAdquirida) }}
								{{ firstLetterUpperCase(pedido.unidad) }}
							</p>
						</div>
						<div class="flex items-center">
							<div
								v-if="pedido.comprado == 0 || pedido.comprado === false"
								class="text-red-500"
							>
								No comprado
							</div>
							<div v-else class="text-green-500">Comprado</div>
						</div>
						<div class="flex items-center">
							<input
								type="checkbox"
								:value="pedido.id"
								v-model="verificarListaCheck"
							/>
						</div>
					</div>
				</div>
			</div>
			<div
				v-if="estatusOrdenCompra === 'completado'"
				class="flex justify-around mx-4 my-10"
			>
				<router-link to="/VHome"
					><botonP class="underline" botonpText="Regresar a home"
				/></router-link>
				<botonP
					v-if="verificarListaCheck.length === pedidosVendedor.length"
					class="underline"
					botonpText="Verificado"
					@click="verificar()"
				/>
			</div>
			<div class="flex justify-around mx-4 my-10" v-else>
				<router-link to="/VHome"
					><botonP class="underline" botonpText="Regresar a home"
				/></router-link>
				<botonP
					v-if="verificarListaCheck.length === pedidosVendedor.length"
					class="underline"
					botonpText="Entregado"
					@click="verificar()"
				/>
			</div>
		</div>
		<div
			v-if="estatusOrdenCompra === 'entregado'"
			class="flex justify-center mx-4 my-10"
		>
			<router-link to="/VHome"
				><botonP class="underline" botonpText="Regresar a home"
			/></router-link>
		</div>
	</div>
</template>

<script>
import gql from "graphql-tag";
import tarjetaCompra from "../components/tarjetaCompraVendedor.vue";
import botonP from "@/components/botonPrimario.vue";
import { formato, firstLetterUpperCase } from "@/functions.js";
import { concatAST } from "graphql";

export default {
	components: {
		tarjetaCompra,
		botonP
	},
	data() {
		return {
			isLoading: false,
			temporal: [],
			filtroCompra: false,
			estatusOrdenCompra: "",
			compraLista: false,
			mostrarListaCompletados: false,
			compradoNoCompradoFiltro: true,
			pedidosVendedor: [],
			cliente: [],
			contador: 0,
			verificarListaCheck: [],
			idCurrentUsuario: "",
			indexTest: []
		};
	},
	async mounted() {
		//Traigo los pedidos pendientes por el vendedor
		this.$store.state.isLoading = true;
		let arrayPedidoStorage = JSON.parse(
			window.localStorage.getItem("pedidosVendedor")
		);
		let compraObject = [];
		if (arrayPedidoStorage != null) {
			compraObject = arrayPedidoStorage.filter(
				e => parseInt(e.compraId) === parseInt(this.$route.params.id)
			);
		}
		if (compraObject.length > 0) {
			this.pedidosVendedor = JSON.parse(JSON.stringify(compraObject[0].pedido));
		} else {
			await this.pedidoVendedordata();
		}
		await this.estatusOrdenCompraActual();
		await this.idUsuario();
		this.$store.state.isLoading = false;
	},
	methods: {
		formato,
		async recargarPedidoVendedor() {
			this.mostrarListaCompletados = false;
			//Clear pedidosVendedor data
			this.pedidosVendedor = [];
			await this.pedidoVendedordata();
		},
		async idUsuario() {
			this.$store.state.isLoading = true;
			await this.$apollo
				.query({
					query: require("@/graphql/queries/idUsuario.gql")
				})
				.then(data => {
					this.idCurrentUsuario = data.data.me.id;
					this.$store.state.isLoading = false;
				})
				.catch(() => {
					this.idCurrentUsuario = "0";
					this.$store.state.isLoading = false;
				});
		},
		//Verificado para pasar al estado finalizado
		verificar() {
			this.verificarListaCheck = [];
			if (this.estatusOrdenCompra === "finalizado") {
				this.actualizarEstatusOrdenCompra("entregado");
			}
			if (this.estatusOrdenCompra === "completado") {
				this.actualizarEstatusOrdenCompra("finalizado");
				this.clientePedido();
			}
		},
		comprar() {
			let arrayPedidoStorage = JSON.parse(
				window.localStorage.getItem("pedidosVendedor")
			);
			let compraObject = [];
			let arrayPedidoNull = true;
			if (arrayPedidoStorage != null) {
				compraObject = arrayPedidoStorage.filter(
					e => parseInt(e.compraId) === parseInt(this.$route.params.id)
				);
				arrayPedidoNull = false;
			}
			if (compraObject.length > 0) {
				arrayPedidoStorage.map((e, index) =>
					parseInt(e.compraId) === parseInt(this.$route.params.id)
						? (arrayPedidoStorage[index].pedido = JSON.parse(
								JSON.stringify(this.pedidosVendedor)
						  ))
						: e
				);
				window.localStorage.setItem(
					"pedidosVendedor",
					JSON.stringify(arrayPedidoStorage)
				);
			} else {
				if (arrayPedidoNull) {
					window.localStorage.setItem(
						"pedidosVendedor",
						JSON.stringify([
							{
								compraId: this.$route.params.id,
								pedido: this.pedidosVendedor
							}
						])
					);
				} else {
					arrayPedidoStorage.push({
						compraId: this.$route.params.id,
						pedido: this.pedidosVendedor
					});
					window.localStorage.setItem(
						"pedidosVendedor",
						JSON.stringify(arrayPedidoStorage)
					);
				}
			}
			this.actualizarPedido();
			this.mostrarListaCompletados = true;
		},
		reset() {
			for (let index = 0; index < this.pedidosVendedor.length; index++) {
				this.pedidosVendedor[index].comprado = !this.pedidosVendedor[index]
					.comprado;
			}
			setTimeout(() => {
				for (let index = 0; index < this.pedidosVendedor.length; index++) {
					this.pedidosVendedor[index].comprado = !this.pedidosVendedor[index]
						.comprado;
				}
			}, 1);
		},
		actualizarObjetoPedido(pedido) {
			this.pedidosVendedor = pedido;
		},
		firstLetterUpperCase,
		async estatusOrdenCompraActual() {
			this.$store.state.isLoading = true;
			await this.$apollo
				.query({
					query: gql`
						query compraIdTotal($cid: ID!) {
							compraIdTotal(id: $cid) {
								estatus
								tasa_dia
							}
						}
					`,
					variables: {
						cid: this.$route.params.id
					},
					fetchPolicy: "no-cache"
				})
				.then(data => {
					this.$store.state.isLoading = false;
					this.estatusOrdenCompra = data.data.compraIdTotal[0].estatus;
					this.tasaDia = data.data.compraIdTotal[0].tasa_dia;
					if (this.estatusOrdenCompra === "finalizado") {
						this.clientePedido();
					}

					//correccion bug de no verificacion porque falta un elemento del array
					//se eliminará un elemento del array si no fue comprado en estos estados.
					this.actualizarArrayPedidoVendedor();
				})
				.catch(error => {
					console.log(error);
					this.$store.state.isLoading = false;
				});
			//this.estadoVerificacion();
		},

		actualizarArrayPedidoVendedor() {
			if (
				this.estatusOrdenCompra === "finalizado" ||
				this.estatusOrdenCompra === "completado"
			) {
				this.pedidosVendedor = JSON.parse(
					JSON.stringify(
						this.pedidosVendedor.filter(
							e => parseFloat(e.cantidadAdquirida) > 0
						)
					)
				);
			}

			if (this.estatusOrdenCompra === "ejecucion") {
				this.pedidosVendedor = JSON.parse(
					JSON.stringify(
						this.pedidosVendedor.filter(
							e => parseFloat(e.cantidadAdquirida) != 0
						)
					)
				);
			}
		},

		//Metodo para traer los productos pendientes de la orden de compra
		async pedidoVendedordata() {
			this.$store.state.isLoading = true;
			await this.$apollo
				.query({
					query: gql`
						query vendedorPedidoCompra($compras_id: ID!) {
							vendedorPedidoCompra(compras_id: $compras_id) {
								id
								producto_id
								cantidad
								cantidad_adquirida
								nombre_producto
								rubro
								comentario
								comprado
								unidad
								precios_id
								tasa_proveedores
								locales {
									local_id
									nombre_local
									precio_id
								}
							}
						}
					`,
					variables: {
						compras_id: parseInt(this.$route.params.id)
					},
					fetchPolicy: "no-cache"
				})
				.then(data => {
					let locales = {
						agotado: false,
						local_id: "",
						nombre_local: "",
						precio_id: ""
					};
					let localesArray = [];
					let comprado = false;
					let cantidadAdquirida = "";
					let posicionLocal = 0;

					for (let i = 0; i < data.data.vendedorPedidoCompra.length; i++) {
						localesArray = [];
						posicionLocal = 0;
						for (
							let j = 0;
							j < data.data.vendedorPedidoCompra[i].locales.length;
							j++
						) {
							//Local position when user buys a product to know what supplier selled it
							if (
								data.data.vendedorPedidoCompra[i].comprado === 1 &&
								data.data.vendedorPedidoCompra[i].precios_id ===
									data.data.vendedorPedidoCompra[i].locales[j].precio_id
							) {
								posicionLocal = j;
							}
							locales = {
								agotado: false,
								local_id: data.data.vendedorPedidoCompra[i].locales[j].local_id,
								nombre_local:
									data.data.vendedorPedidoCompra[i].locales[j].nombre_local,
								precio_id:
									data.data.vendedorPedidoCompra[i].locales[j].precio_id
							};
							localesArray.push(locales);
						}
						comprado = false;
						cantidadAdquirida = "";
						if (data.data.vendedorPedidoCompra[i].comprado === 1) {
							comprado = true;
							cantidadAdquirida = data.data.vendedorPedidoCompra[i].cantidad;
						}
						this.pedidosVendedor.push({
							id: data.data.vendedorPedidoCompra[i].id,
							producto_id: data.data.vendedorPedidoCompra[i].producto_id,
							cantidad: data.data.vendedorPedidoCompra[i].cantidad,
							cantidadAdquirida:
								data.data.vendedorPedidoCompra[i].cantidad_adquirida,
							nombre_producto: this.firstLetterUpperCase(
								data.data.vendedorPedidoCompra[i].nombre_producto
							),
							rubro: data.data.vendedorPedidoCompra[i].rubro,
							locales: localesArray,
							unidad: this.firstLetterUpperCase(
								data.data.vendedorPedidoCompra[i].unidad
							),
							comentario: data.data.vendedorPedidoCompra[i].comentario,
							comprado: comprado,
							posicionLocal: posicionLocal
						});
					}

					this.eliminarProductoAgotado();
					this.$store.state.isLoading = false;
				})
				.catch(error => {
					console.log(error);
					this.$store.state.isLoading = false;
				});
		},
		//Metodo para saber el estado de la orden de compra

		async clientePedido() {
			this.$store.state.isLoading = true;
			await this.$apollo
				.query({
					query: gql`
						query($id: ID!) {
							clienteCompraID(id: $id) {
								user {
									name
									numero_telefono_movil
									address
								}
							}
						}
					`,
					variables: {
						id: parseInt(this.$route.params.id)
					}
				})
				.then(data => {
					this.$store.state.isLoading = false;
					this.cliente = data.data.clienteCompraID[0].user;
				})
				.catch(error => {
					console.log(error);
					this.$store.state.isLoading = false;
				});
		},
		duplicarTarjeta(index, pedido) {
			console.log("aqui");
			if (
				parseFloat(this.pedidosVendedor[index].cantidad) -
					parseFloat(this.pedidosVendedor[index].cantidadAdquirida) ==
				0
			) {
				this.pedidosVendedor[index].comprado = true;
			} else {
				console.log("aqui1");
				if (
					parseFloat(this.pedidosVendedor[index].cantidadAdquirida) <=
					parseFloat(this.pedidosVendedor[index].cantidad) + 0.3
				) {
					console.log("aqui2");
					let idx = this.pedidosVendedor.indexOf(pedido); //detecta el index por semejanza
					this.idx = idx;
					console.log("idx", idx);
					if (this.pedidosVendedor[index].locales.length > 1) {
						let temporal = {
							id: "",
							producto_id: "",
							cantidad: "",
							cantidadAdquirida: "",
							nombre_producto: "",
							rubro: "",
							locales: [],
							unidad: "",
							comentario: "",
							comprado: false,
							posicionLocal: 0,
							agotado: false
						};
						let localesArrayTemporal = [];

						if (this.pedidosVendedor[index].cantidadAdquirida != "") {
							temporal.id = this.contador--;
							temporal.producto_id = this.pedidosVendedor[idx].producto_id;
							temporal.nombre_producto = this.pedidosVendedor[
								idx
							].nombre_producto;
							temporal.cantidad = (
								parseFloat(this.pedidosVendedor[index].cantidad) -
								parseFloat(this.pedidosVendedor[index].cantidadAdquirida)
							).toFixed(2);
							temporal.unidad = this.pedidosVendedor[idx].unidad;
							//temporal.comprado = this.pedidosVendedor[idx].comprado;
							localesArrayTemporal = JSON.parse(
								JSON.stringify(this.pedidosVendedor[idx].locales)
							);
							console.log("uno", localesArrayTemporal);
							localesArrayTemporal.splice(
								this.pedidosVendedor[idx].posicionLocal,
								1
							);
							console.log("dos", localesArrayTemporal);
							console.log("temporal", temporal);
							temporal.locales = localesArrayTemporal;
							this.pedidosVendedor[idx].cantidad = temporal.cantidad;

							this.pedidosVendedor.splice(idx + 1, 0, temporal); //inserta elemento en la posicion indicada, el primer argumento indica la posicion, segundo argumento indica si se eliminara algun elemento(aun no se como funciona la parte de borrado), a partir del tercer argumento elemento a insertar, puede insertarse mas de un elemento, simplemento agregandolo como argumentos extras despues del tercero
							this.pedidosVendedor[index].comprado = true;
							this.temporal[this.contadorTemporal] = temporal;
							this.contadorTemporal++;
						} else {
							alert("Debe ingresar una cantidad para duplicar tarjeta");
						}
					} else {
						alert("No hay más sugerencia de proveedores, producto agotado.");
						this.pedidosVendedor[index].agotado = true;
						this.pedidosVendedor[index].locales[
							this.pedidosVendedor[index].posicionLocal
						].agotado = true;
					}
				} else {
					alert(
						"La cantidad adquirida no puede ser mayor a la cantidad solicitada"
					);
				}
			}
		},
		eliminarProductoAgotado() {
			//Eliminar del array producto agotado
			let posicionLocalEliminar = [];
			let posicionProductoEliminar = [];

			for (let i = 0; i < this.pedidosVendedor.length; i++) {
				for (let j = 0; j < this.pedidosVendedor[i].locales.length; j++) {
					if (this.pedidosVendedor[i].locales[j].agotado) {
						posicionLocalEliminar.push(j);
					}
				}
				for (let j = posicionLocalEliminar.length - 1; j >= 0; j--) {
					this.pedidosVendedor[i].locales.splice(posicionLocalEliminar[j], 1);
				}
				posicionLocalEliminar = [];
				if (
					this.pedidosVendedor[i].locales.length < 1 &&
					this.pedidosVendedor[i].comprado === 0
				) {
					posicionProductoEliminar.push(i);
				}
			}

			for (let j = posicionProductoEliminar.length - 1; j >= 0; j--) {
				this.pedidosVendedor.splice(posicionProductoEliminar[j], 1);
			}
		},
		async actualizarPedido() {
			let dataActualizada = [];
			let cantidad = "";
			let localesArray = [];
			let localesArrayAux = [];
			//Construir arreglo para actualizar tabla pedidos
			for (let index = 0; index < this.pedidosVendedor.length; index++) {
				if (this.pedidosVendedor[index].cantidadAdquirida != "") {
					cantidad = this.pedidosVendedor[index].cantidadAdquirida;
				}

				if (cantidad > 0) {
					dataActualizada.push({
						id: this.pedidosVendedor[index].id,
						cantidad: this.pedidosVendedor[index].cantidad,
						cantidad_adquirida: cantidad,
						comprado: this.pedidosVendedor[index].comprado,
						precio_id: this.pedidosVendedor[index].locales[
							this.pedidosVendedor[index].posicionLocal
						].precio_id
					});
				} else {
					dataActualizada.push({
						id: this.pedidosVendedor[index].id,
						cantidad: this.pedidosVendedor[index].cantidad,
						cantidad_adquirida: cantidad,
						comprado: false,
						precio_id: this.pedidosVendedor[index].locales[
							this.pedidosVendedor[index].posicionLocal
						].precio_id
					});
				}

				//let existencia = true;
				//Arreglo de locales totales del pedido para la actualización
				// Si el producto tiene un comentario y se marca como agotado el sistema NO lo actualizará en la base de datos
				for (let j = 0; j < this.pedidosVendedor[index].locales.length; j++) {
					localesArrayAux.push({
						precio_id: this.pedidosVendedor[index].locales[j].precio_id,
						existencia: !this.pedidosVendedor[index].locales[j].agotado
					});
				}
			}

			/* Elimina los duplicados del localesArrayAux para determinar que productos estan agotados */

			let ArrayAux = localesArrayAux.filter(e => {
				if (!e.existencia) {
					return e;
				}
			});

			let ArrayAux2 = localesArrayAux.map(e => {
				ArrayAux.map(x => {
					if (e.precio_id === x.precio_id) {
						e.existencia = x.existencia;
					}
				});
				return e;
			});
			localesArray = ArrayAux2.filter(
				(value, index, self) =>
					index ===
					self.findIndex(
						t =>
							t.precio_id === value.precio_id &&
							t.existencia === value.existencia
					)
			);
			this.$store.state.isLoading = true;
			//mutacion para actualizar tabla pedidos
			await this.$apollo
				.mutate({
					mutation: gql`
						mutation(
							$compra_id: ID!
							$data: [dataPedidoCompra]
							$dataLocal: [LocalesArray]
						) {
							actualizarPedidoCompra(
								compra_id: $compra_id
								data: $data
								dataLocal: $dataLocal
							) {
								estatus
							}
						}
					`,
					variables: {
						data: dataActualizada,
						dataLocal: localesArray,
						compra_id: parseInt(this.$route.params.id)
					}
				})
				.then(data => {
					if (
						this.estatusOrdenCompra !=
						data.data.actualizarPedidoCompra[0].estatus
					) {
						this.notificarBroadcasting(
							this.firstLetterUpperCase(
								data.data.actualizarPedidoCompra[0].estatus
							),
							this.$route.params.id
						);
					}
					this.estatusOrdenCompra = data.data.actualizarPedidoCompra[0].estatus;
					this.eliminarProductoAgotado();
					for (let i = 0; i < this.pedidosVendedor.length; i++) {
						if (this.pedidosVendedor[i].locales.length < 1) {
							this.pedidosVendedor.splice(i, 1);
						}
					}
					this.actualizarArrayPedidoVendedor();
					this.actualizarOrdenCompra();
					this.$store.state.isLoading = false;
				})
				.catch(error => {
					this.$store.state.isLoading = false;
					this.errorMutation(
						"actualizarPedidoCompra",
						error,
						JSON.stringify([
							parseInt(this.$route.params.id),
							dataActualizada,
							localesArray
						])
					);
					alert("Error de carga de datos, por favor verificar data ingresada.");
				});
		},
		async actualizarOrdenCompra() {
			this.$store.state.isLoading = true;
			await this.$apollo
				.mutate({
					mutation: gql`
						mutation($compras_id: ID!) {
							actualizarOrdenCompra(compras_id: $compras_id) {
								__typename
							}
						}
					`,
					variables: {
						compras_id: parseInt(this.$route.params.id)
					}
				})
				.then(() => {
					this.$store.state.isLoading = false;
					this.borrarLocalStorage();
					alert(
						"Cotización N° " +
							this.$route.params.id.padStart(4, "0") +
							" actualizada con exito."
					);
				})
				.catch(error => {
					console.log(error);
					this.$store.state.isLoading = false;
					this.errorMutation(
						"actualizarOrdenCompra",
						error,
						JSON.stringify(parseInt(this.$route.params.id))
					);
					alert("Error de carga de datos, por favor verificar data ingresada.");
				});
		},
		async actualizarEstatusOrdenCompra(estatus) {
			this.entregado = !this.entregado;
			this.$store.state.isLoading = true;
			await this.$apollo
				.mutate({
					mutation: gql`
						mutation($id: ID!, $data: UpdateEstatusCompra!) {
							editarEstadoPedidoVendedor(id: $id, input: $data) {
								id
								estatus
							}
						}
					`,
					variables: {
						id: this.$route.params.id,
						data: {
							estatus: estatus
						}
					}
				})
				.then(data => {
					this.$store.state.isLoading = false;
					if (
						this.estatusOrdenCompra !=
						data.data.editarEstadoPedidoVendedor.estatus
					) {
						this.notificarBroadcasting(
							this.firstLetterUpperCase(
								data.data.editarEstadoPedidoVendedor.estatus
							),
							this.$route.params.id
						);
					}

					this.estatusOrdenCompra =
						data.data.editarEstadoPedidoVendedor.estatus;
					if (this.estatusOrdenCompra === "entregado") {
						this.borrarLocalStorage();
						alert("Pedido entregado con exito");
						this.$router.push("/Vhome");
					}
				})
				.catch(error => {
					this.$store.state.isLoading = false;
					console.log(error);
					this.errorMutation(
						"actualizarEstatusOrdenCompra",
						error,
						JSON.stringify(estatus)
					);
					alert("Error de carga de datos, por favor verificar data ingresada.");
				});
		},
		async errorMutation(method, error, variable) {
			this.$store.state.isLoading = true;
			await this.$apollo
				.mutate({
					mutation: require("@/graphql/mutations/ErrorMutation.gql"),
					variables: {
						data: {
							vista: "VendedorPedido.vue",
							compra_id: String(this.$route.params.id),
							method: method,
							error: String(error),
							variable: variable
						}
					}
				})
				.then(() => {
					this.$store.state.isLoading = false;
				})
				.catch(error => {
					this.$store.state.isLoading = false;
					console.log(error);
					alert("Error de comunicación, por favor vuelva a intentar.");
				});
		},
		notificarBroadcasting(estatusOrdenCompra, idOrdenCompra) {
			window.axios.post(
				process.env.VUE_APP_BACKEND_URL + "/tipoCambioMensaje",
				{
					message:
						"Orden de compra N°" +
						String(idOrdenCompra).padStart(4, "0") +
						" estado: " +
						estatusOrdenCompra +
						".",
					user_receptor: "G-SU",
					user_emisor: this.idCurrentUsuario,
					compras_id: String(this.$route.params.id),
					check: "1"
				}
			);
		},
		borrarLocalStorage() {
			//borrar pedido del storage del vendedor
			let arrayPedidoStorage = JSON.parse(
				window.localStorage.getItem("pedidosVendedor")
			);
			if (arrayPedidoStorage != null) {
				let indexPedido = arrayPedidoStorage.findIndex(
					e => parseInt(e.compraId) == parseInt(this.$route.params.id)
				);
				if (indexPedido >= 0) {
					arrayPedidoStorage.splice(indexPedido, 1);
					window.localStorage.setItem(
						"pedidosVendedor",
						JSON.stringify(arrayPedidoStorage)
					);
				}
			}
		}
	}
};
</script>

<style></style>
