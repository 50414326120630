<template>
	<div>
		<div
			class="flex mt-1 mx-auto font-bold justify-between"
			:class="mostrarComprado ? 'block' : 'hidden'"
		>
			<div class="w-5/6 h-auto">
				<div class="my-4 w-full">
					<div class="w-full items-centerfont-bold px-2">
						<p>Producto: {{ pedido.nombre_producto }}</p>
						<div class="my-2">
							<p>
								Solicitado: {{ pedido.cantidad }}
								{{ pedido.unidad }}
							</p>
						</div>
					</div>
				</div>
				<div class="mb-4">
					<div class="flex justify-center">
						<div v-if="!pedido.comprado" class="flex mx-1 items-center">
							<p class="mr-2">Cantidad comprada:</p>
							<input
								class="mx-2 p-1 rounded-lg w-32 sm:w-auto focus:outline-none px-auto border border-black"
								type="number"
								step="0.01"
								v-model="pedido.cantidadAdquirida"
							/>
							<p class="ml-1">{{ pedido.unidad }}</p>
						</div>
						<div v-else class="flex mx-1 items-center">
							<p class="mr-2">Cantidad comprada:</p>
							<p class="ml-1">
								{{ " " + pedido.cantidadAdquirida + " " + pedido.unidad }}
							</p>
						</div>
					</div>
				</div>
				<div class="my-4">
					<div v-if="localesDisponibles" class="flex items-center ml-2">
						<p class="mr-2">Local:</p>
						<select
							class="p-1 w-56 bg-white border border-secundB rounded-lg"
							name="local"
							v-model="pedido.posicionLocal"
						>
							<option :value="0" selected hidden disabled>
								{{ firstLetterUpperCase(pedido.locales[0].nombre_local) }}
							</option>
							<option v-for="(local, j) in pedido.locales" :key="j" :value="j">
								{{ firstLetterUpperCase(local.nombre_local) }}
							</option>
						</select>
					</div>
					<div v-else>
						<p class="ml-2">Producto agotado</p>
					</div>
				</div>
				<div class="flex items-center justify-between my-4 mx-2 px-2">
					<div class="flex items-center">
						<input
							class="transform scale-150"
							type="checkbox"
							v-model="pedido.comprado"
							@click.prevent="verificarCantidadComprada"
						/>
						<span class="underline ml-2">Comprado</span>
					</div>
					<div class="flex items-center">
						<input
							class="transform scale-150"
							type="checkbox"
							v-model="pedido.locales[pedido.posicionLocal].agotado"
						/>
						<span class="underline ml-2"> Agotado</span>
					</div>
				</div>
				<div class="w-full ml-2 my-4 pb-2 border-b border-black">
					<span class="w-full">Comentarios: </span>
					<textarea
						type="text"
						class="rounded-lg border-2 w-64 h-auto border-secunB focus:outline-none px-auto bg-yellow-400 font-bold"
						v-model="pedido.comentario"
					></textarea>
				</div>
			</div>
			<div class="flex items-center cursor-pointer ml-1 mr-1">
				<svg
					@click="duplicarTarjetaChild()"
					id="Capa_1"
					enable-background="new 0 0 551.13 551.13"
					height="512"
					viewBox="0 0 551.13 551.13"
					width="512"
					class="w-10 h-10 mr-1 fill-current text-blue-300"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="m275.565 0c-151.944 0-275.565 123.621-275.565 275.565s123.621 275.565 275.565 275.565 275.565-123.621 275.565-275.565-123.621-275.565-275.565-275.565zm0 516.685c-132.955 0-241.119-108.164-241.119-241.119s108.164-241.12 241.119-241.12 241.12 108.164 241.12 241.119-108.165 241.12-241.12 241.12z"
					/>
					<path
						d="m292.788 137.783h-34.446v120.56h-120.56v34.446h120.56v120.56h34.446v-120.56h120.56v-34.446h-120.56z"
					/>
				</svg>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		pedido: Object,
		mostrarTarjeta: Boolean,
		posicionArray: Number
	},

	data() {
		return {
			checkCompra: false,
			localesDisponibles: false
		};
	},
	computed: {
		mostrarComprado() {
			let mostrarItem = false;
			if (this.mostrarTarjeta) {
				if (this.pedido.comprado) {
					mostrarItem = true;
				} else {
					mostrarItem = false;
				}
			} else {
				if (!this.pedido.comprado) {
					mostrarItem = true;
				} else {
					mostrarItem = false;
				}
			}
			return mostrarItem;
		}
	},
	mounted() {
		this.checkCompra = this.pedido.comprado;
		//En caso de existir locales disponibles se muestra el array
		if (this.pedido.locales.length >= 1) {
			this.localesDisponibles = true;
		} else {
			//Se crea un array para no generar un error al momento de montar el componente, se coloca agotado automaticamente
			this.pedido.locales.push({
				agotado: true,
				nombre_local: "NA"
			});
			this.localesDisponibles = false;
			this.pedido.agotado = true;
			this.pedido.cantidadAdquirida = 0;
		}
	},
	methods: {
		firstLetterUpperCase(word) {
			return word.charAt(0).toUpperCase() + word.slice(1);
		},
		suma() {
			this.contador++;
			this.$emit("incrementarContador", this.contador);
		},
		verificarCantidadComprada() {
			console.log("1");
			if (
				this.pedido.posicionLocal < this.pedido.locales.length - 1 &&
				//!this.pedido.locales[this.pedido.posicionLocal].agotado &&
				parseFloat(this.pedido.cantidadAdquirida) === 0
			) {
				console.log("2");
				/* this.pedido.locales[this.pedido.posicionLocal].agotado = true;
				this.pedido.posicionLocal++; */

				//if (this.pedido.posicionLocal >= this.pedido.locales.length - 1) {
				//modificado 10/03/22
				this.pedido.comprado = true;
				/* if (this.pedido.agotado) {
					this.pedido.agotado = true;
				} */
				this.pedido.agotado = true;
				this.duplicarTarjetaChild();
				console.log("3");
				//}
			} else {
				if (!this.pedido.comprado) {
					console.log("4");
					if (!this.pedido.locales[this.pedido.posicionLocal].agotado) {
						console.log("5");
						if (
							(parseFloat(this.pedido.cantidadAdquirida) <=
								parseFloat(this.pedido.cantidad) + 0.3 &&
								parseFloat(this.pedido.cantidadAdquirida) >=
									parseFloat(this.pedido.cantidad) - 0.3) ||
							this.pedido.agotado
						) {
							console.log("6");
							this.pedido.comprado = true;
							//this.mostrarTarjeta = false;
						} else {
							console.log("7");
							this.pedido.comprado = false;
							alert(
								"La cantidad comprada debe estar entre " +
									(parseFloat(this.pedido.cantidad) - 0.3) +
									" y " +
									(parseFloat(this.pedido.cantidad) + 0.3) +
									" " +
									this.firstLetterUpperCase(this.pedido.unidad)
							);
							this.pedido.comprado = false;
							//this.mostrarTarjeta = true;
						}
					} else {
						console.log("8");
						if (
							parseFloat(this.pedido.cantidadAdquirida) >
							parseFloat(this.pedido.cantidad) + 0.3
						) {
							alert(
								"La cantidad adquirida no puede ser mayor a la cantidad solicitada"
							);
						} else {
							this.pedido.comprado = true;
							this.duplicarTarjetaChild();
						}

						//this.mostrarTarjeta = true;
					}
				} else {
					this.pedido.comprado = false;
				}
			}
		},
		resetAll() {
			setTimeout(() => {
				this.pedidocomprado = !this.pedido.comprado;
			}, 100);
		},
		duplicarTarjetaChild() {
			this.$emit("eventoDuplicarTarjeta", this.pedido);
		}
	}
};
</script>

<style></style>
